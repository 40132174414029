import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Card, Col, Nav, NavItem, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { AiOutlineFilePdf, AiOutlineWifi } from "react-icons/ai";
import { CgGames, CgGym } from "react-icons/cg";
import { FaInfoCircle, FaSwimmingPool } from "react-icons/fa";
import { FiArchive } from "react-icons/fi";
import { GrFan } from "react-icons/gr";
import {
  MdLocalLaundryService,
  MdOutdoorGrill,
  MdOutlineDesktopMac,
  MdOutlineSecurity,
  MdSportsVolleyball,
} from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import LightBox from "../../components/LightBox";
import {
  useGetPropertyById,
  useGetPropertyDocumentByPropertyId,
} from "../../hooks/useGetPropertyById";
import { GetRoleNavigationPath } from "../../routeUtils";
import BalancePage from "../Property/BalancePage";
import OrderPage from "../Property/OrderPage";
import ReserveTokenPage from "../Property/ReserveTokenPage";
import SellTokenPage from "../Property/SellTokenPage";
import TransfersPage from "../Property/TransferPage";
import "./../../components/previewProperty.css";
import { useTabContext } from "./TabContext";
import { useAuth } from "../../auth/TokenCreation";
import Footer from "../../components/Footer";
import avalanche from "../../assets/img/avalanch.png";
import Info from "../../components/Tooltip";
import OrderBook from "../../components/OrderBook";
import { usePropertyHistorical } from "../../hooks/useCreatePropertyHistroical";
import CarreToken from "../../assets/icons/CarreToken";
import { checkPlural } from "../../utils/helper";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";

function PeviewProperty() {
  const params = useParams();
  const id = params.id;
  const { data, refetch } = useGetPropertyById(id);
  const navigate = useNavigate();

  const { data: propertyDocument, refetch: propertyDocumentRefetch } =
    useGetPropertyDocumentByPropertyId(id);
  const { data: propertyHistorical } = usePropertyHistorical(id);

  let allFalse =
    data?.amenities &&
    Object.values(data?.amenities)?.every((value) => value === false);

  const [padding, setPadding] = useState(
    window.innerWidth <= 991 && window.innerWidth >= 200 ? "10px" : "40px"
  );
  const [paddingTop, setPaddingTop] = useState(
    window.innerWidth <= 991 && window.innerWidth >= 200 ? "15px" : "0px"
  );
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 991 && windowWidth >= 200) {
        // MD and SM
        setPadding("10px");
        setPaddingTop("15px");
      } else {
        setPadding("40px");
        setPaddingTop("0px");
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cards = [
    {
      bg: "bg-carre-blue",
      style: "fs-lg text-white",
      title: `Share Price`,
      titleInfo:
        "The price at which each individual share or ownership unit of a property is bought or sold on the Carré platform. It represents the value attributed to each share of the property.\n",
      cardTitleValue: `$ ${data?.token_price}`,
      values: [
        `$ ${data?.total_costs.toLocaleString("en-US", {
          currency: "USD",
        })}`,
        data?.market_stage_name === "Upcoming"
          ? data.allowed_token_to_reserve - data.reserved_token
          : data?.available_token.toLocaleString("en-US"),
        data?.number_of_token.toLocaleString("en-US"),
      ],
      infoValues: [
        "The estimated monetary worth or market value of a specific property listed on the Carre platform. It reflects the overall assessed value of the property based on factors such as location, size, condition, and potential for rental income and appreciation.\n",
        ' "Available shares" indicates the quantity of ownership units that are still available for purchase in that specific property',
        "The total number of ownership units or shares available for a particular property listed on the Carre platform. It represents the entirety of the property's ownership divided into individual units that investors can purchase. ",
      ],

      text: ["Property value", "Available shares", "Total shares"],
    },
    {
      style: "text-carre-blue fs-lg",
      title: "Expected Return",
      titleInfo:
        "The Expected (Annual) Total Return is the sum of three annual return that Carre offers to his investors",
      class: "mx-0 mx-md-2",
      cardTitleValue: `${(
        data?.net_expected_cash_return +
        data?.vacation_token_exceptions +
        data?.net_appreciation
      ).toFixed(2)} %`,
      values: [
        `${data?.net_expected_cash_return.toFixed(2)}%`,
        `${data?.vacation_token_exceptions.toFixed(2)}%`,
        `${data?.net_appreciation.toFixed(2)}%`,
      ],
      infoValues: [
        " Earn daily income from rental payments generated by the properties you invest in.\n",

        "  Enjoy the flexibility to use vacation points for complimentary stays at our properties, adding an extra perk to your investment portfolio. Vacation Points can also be sold on the platform",
        "  Benefit from the potential increase in property value over time, allowing your investment to grow.\n",
      ],
      text: ["Rental", "Vacation point", "Appreciation"],
    },
  ];

  // Check if market_stage_name is "Upcoming" and add the third card if true
  if (data?.market_stage_name !== "Upcoming") {
    cards.push({
      style: "fs-lg text-carre-blue",
      title: "Current Return",
      titleInfo:
        '"Current return" refers to the total earnings generated from rental income and vacation points income for a specific period, typically calculated on a daily basis. It represents the combined sum of the annual return from the current end-of-day cash flow of both rental income and vacation points income. Notably, appreciation, which reflects the increase in the value of the property over time, is not included in the current return calculation as it does not contribute to the daily cash flow.',
      cardTitleValue: `${(
        ((data?.cash_dividend_payment * data?.number_of_token * 365) /
          data?.total_costs) *
          100 +
        ((data?.vacation_token_price * data?.number_of_token * 365) /
          data?.total_costs) *
          100
      ).toFixed(2)} %`,
      values: [
        `${(
          ((data?.cash_dividend_payment * data?.number_of_token * 365) /
            data?.total_costs) *
          100
        ).toFixed(2)}%`,
        `${(
          ((data?.vacation_token_price * data?.number_of_token * 365) /
            data?.total_costs) *
          100
        ).toFixed(2)}%`,
        "Not a daily cash flow",
      ],
      text: ["Rental", "Vacation point", "Appreciation"],
    });
  }

  const { setSelectedPdf } = useTabContext();

  const redirectToPdf = (documentUrl) => {
    // Assuming you have a function like setSelectedPdf to update the selected PDF
    setSelectedPdf(documentUrl);
    navigate("/pdf");
  };

  function show(param) {
    switch (param) {
      default:
        return;
      case "DETAILS":
        return (
          <div className="container mb-5">
            {/* Overview */}
            <div className="col my-4">
              <h4 className="text-black">Occupancy Status</h4>
              {data?.rental_type === "LONG_TERM" &&
              data?.rental_type_status === "OCCUPIED" ? (
                <>
                  <p className="text-muted">
                    <span className="fw-bold">Status: </span>Tenant Occupied
                  </p>
                  <p className="text-muted">
                    <span className="fw-bold">End Lease: </span>
                    {data?.end_lease?.slice(0, 10)}
                  </p>
                  <p className="text-muted">
                    <span className="fw-bold">Occupied Until: </span>
                    {data?.occupied_until?.slice(0, 10)}
                  </p>
                  <p className="text-muted">
                    <span className="fw-bold">Monthly Rental Income: </span>
                    {data?.monthly_rental_income.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    /month
                  </p>
                </>
              ) : data?.rental_type === "SHORT_TERM" &&
                data?.rental_type_status === "OCCUPIED" ? (
                <>
                  <p className="text-muted">
                    <span className="fw-bold">Status: </span>Tenant Occupied
                  </p>
                  <p className="text-muted">
                    <span className="fw-bold">Daily Rental Income: </span>
                    {data?.daily_rental_income.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    /day
                  </p>
                  <p className="text-muted">
                    <span className="fw-bold">Expected Rental Days: </span>
                    {data?.expected_number_of_rented_days}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-muted">
                    <span className="fw-bold">Status: </span>Vacant
                  </p>
                </>
              )}
            </div>
            <div className="my-4">
              <h3>Overview</h3>
              <p
                className="overflow-hidden text-muted collapse show "
                id="collapseSummary"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></p>
            </div>
            {/* Property Managment and Occupancy Status */}
            <div className="mt-5 row">
              <Col md={12}>
                <h4 className="text-black">
                  Property Management{" "}
                  {data?.insurance_url !== "" && "and Insurance"}
                </h4>
                <p className="text-muted">
                  {data?.property_management_url !== "" && (
                    <>
                      <span className="fw-bold">Managed by: &nbsp;</span>

                      <Link
                        target="_blank"
                        to={`${data?.property_management_url}`}
                      >
                        {data?.property_management_name}
                      </Link>
                    </>
                  )}
                </p>
                <p className="text-muted">
                  {data?.insurance_url !== "" && (
                    <>
                      <span className="fw-bold">
                        Insurance Policy from: &nbsp;
                      </span>

                      <Link target="_blank" to={`${data?.insurance_url}`}>
                        {data?.insurance_name}
                      </Link>
                    </>
                  )}
                </p>
              </Col>
            </div>

            <div className="my-4">
              <h3>{data?.market_title}</h3>
              <p
                className="overflow-hidden text-muted collapse show "
                id="collapseSummary"
                dangerouslySetInnerHTML={{ __html: data?.market_description }}
              ></p>
            </div>
            <Button
              className="h-100 mt-2"
              size="md"
              onClick={() => window.open(data?.contract_url, "_blank")}
            >
              <img src={avalanche} width={15} />
              <div className="tooltip-text fs-xs text-center">
                &nbsp; Block chain address
              </div>
            </Button>
          </div>
        );

      // case "HISTORICALRETURN":
      //   return (
      //     <div className=" mb-5 overflow-auto">
      //       {propertyHistorical && propertyHistorical.length === 0 ? (
      //         <Alert className="text-center fs-sm p-2 fw-semibold">
      //           No historical data available
      //         </Alert>
      //       ) : (
      //         <>
      //           <p className="text-muted fs-md p-1">
      //             Prior to reviewing the historical return table, please note
      //             that the figures provided are pre-tax and pre-other expenses
      //           </p>
      //           <table className="table fs-xs">
      //             <thead>
      //               <tr>
      //                 <th scope="col">Date</th>
      //                 <th scope="col">Net Income ($)</th>
      //                 <th scope="col">Gross Income ($)</th>
      //                 <th scope="col">Cost ($)</th>
      //                 <th scope="col">Return (%)</th>
      //               </tr>
      //             </thead>
      //             <tbody>
      //               {propertyHistorical.map((historicalData, index) => (
      //                 <tr key={index}>
      //                   <td>
      //                     {new Date(historicalData?.date).toLocaleDateString(
      //                       "en-US",
      //                       {
      //                         year: "numeric",
      //                         month: "short",
      //                       }
      //                     )}
      //                   </td>
      //                   <td>{historicalData.income.toLocaleString()}</td>
      //                   <td>{historicalData.gross_income.toLocaleString()}</td>
      //                   <td>{historicalData.cost.toLocaleString()}</td>
      //                   <td>{historicalData.return}</td>
      //                 </tr>
      //               ))}
      //             </tbody>
      //           </table>
      //         </>
      //       )}
      //     </div>
      //   );

      case "FINANCIAL":
        return (
          <div className="container my-4">
            <Row className="border border-2 rounded my-4 ">
              <div>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <span className="text-black fs-md fw-bold">
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text={
                        "Total Investment Value: The overall worth of an investment, including the initial capital contributed (Underlying asset price), additional investments made over time, and any other costs.\n"
                      }
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>{" "}
                    Total Investment Value{" "}
                  </span>
                  <span className="text-carre-blue fs-md fw-bold">
                    {data?.total_costs.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>

                <hr />

                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text={
                            "Upfront Accounting Fees for Opening LLC: The fees incurred at the beginning of the process for establishing a Limited Liability Company (LLC), covering the costs associated with accounting services required for LLC formation.\n"
                          }
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Upfront Accounting Fees for Opening LLC
                      </h6>
                      <p>
                        {data?.doa_llc_fees.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />

                  <Col md={6} className="">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text={
                            "Underlying Asset Price: The value of the underlying asset, such as real estate or securities, upon which an investment is based. It serves as the basis for determining the investment's potential return and risk.\n"
                          }
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>{" "}
                        Underlying Asset Price{" "}
                      </h6>
                      <p>
                        {data?.asset_price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div>
                      <div className="mt-2 mb-2 text-muted">
                        <h6 className="mb-1 ">
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text="The expenses associated with finalizing a real estate transaction, typically paid at the closing or settlement of the property purchase. These costs may include attorney fees, title insurance, appraisal fees, and other related expenses.
 "
                          >
                            <span
                              style={{ marginRight: "4px" }}
                              className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                          Closing Cost
                        </h6>
                        <p>
                          {data?.closing_costs.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />

                  <Col md={6} className="">
                    <div>
                      <div className="mt-2 mb-2 text-muted">
                        <h6 className="mb-1 ">
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text="The fees charged by Carre for listing a property on its platform, facilitating fractional ownership and investment opportunities for users."
                          >
                            <span
                              style={{ marginRight: "4px" }}
                              className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                          Carré Listing Fees
                        </h6>
                        <p>
                          {data?.carre_listing_fees.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div>
                      <div className=" mt-2 mb-2 text-muted">
                        <h6 className="mb-1">
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text="A fund set aside to cover ongoing operational expenses and unforeseen costs associated with managing a property or investment, ensuring financial stability and liquidity.
 "
                          >
                            <span
                              style={{ marginRight: "4px" }}
                              className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                          Operating Reserve
                        </h6>
                        <p>
                          {data?.operating_reserve.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />

                  <Col md={6}>
                    <div>
                      <div className=" mt-2 mb-2 text-muted">
                        <h6 className="mb-1">
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text=" The expenses incurred during the construction or renovation of a property, including materials, labor, furnishings, and any additional costs related to property development or improvement. "
                          >
                            <span
                              style={{ marginRight: "4px" }}
                              className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                          Construction / Furnishing / other costs
                        </h6>
                        <p>
                          {data?.other_costs.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row className="border border-2 rounded my-4">
              <div>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <span className="text-black fs-md fw-bold">
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text="The total income generated from an investment property over the course of one year, including rental income, other sources of revenue, and any additional cash inflows.
 "
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>
                    Annual Cash Flows - Revenue
                  </span>
                  <span className="text-carre-blue fs-md fw-bold">
                    {data?.revenues_total.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>

                <hr />
                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text="The projected total rental income that a property is expected to generate in a year before deducting any expenses or taxes.

 "
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Expected Annual Gross Rental Income
                      </h6>
                      <p>
                        {data?.revenues_rent.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />
                  <Col md={6}>
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text="Additional sources of revenue generated by a property apart from rental income, such as parking fees, laundry services, or other amenities provided to tenants."
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Other Revenues
                      </h6>
                      <p>
                        {data?.revenues_other.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row className="border border-2 rounded p-2 my-4">
              <div className="d-flex justify-content-between align-items-center my-2">
                <span className="text-black fs-md fw-bold">
                  <Info
                    classNames="fs-sm"
                    placement="top"
                    text="The net cash flow generated by an investment property over the course of one year after deducting all expenses, including operating costs, taxes, and debt service, from the gross rental income.

 "
                  >
                    <span
                      style={{ marginRight: "4px" }}
                      className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                    >
                      <FaInfoCircle style={{ marginBottom: 3 }} />
                    </span>
                  </Info>
                  Annual Cash Flows - Net
                </span>
                <span className="text-carre-blue fs-md fw-bold">
                  {data?.net_expected_annual.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </div>

              <hr />
              <div className="">
                <div className="mt-2 mb-2 text-muted ">
                  {/*<Info*/}
                  {/*  // placement="top"*/}
                  {/*  text="Explanation for monthly cash flows"*/}
                  {/*>*/}
                  <h6 className="mb-1">
                    {/*<span>*/}
                    {/*  <FaInfoCircle className="mx-2" />*/}
                    {/*</span>*/}
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text="The anticipated cash flow from an investment property on a monthly basis, calculated by dividing the expected annual cash flow by twelve.
 "
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>
                    Expected Monthly cash flows
                  </h6>
                  {/*</Info>*/}
                  <p>
                    {data?.net_expected_monthly.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                </div>
              </div>
            </Row>
            <Row className="border border-2 rounded my-4">
              <div>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <span className="text-black fs-md fw-bold">
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text="The total expenses associated with owning and operating an investment property over the course of one year, including property taxes, insurance, maintenance costs, and property management fees.
"
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>
                    Annual Cash Flows - Cost
                  </span>
                  <span className="text-carre-blue fs-md fw-bold">
                    {data?.cost_total_cost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>

                <hr />
                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text="Taxes levied by the government on the assessed value of a property, which are typically based on the property's location and assessed value.
 "
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Property Taxes
                      </h6>
                      <p>
                        {data?.cost_property_texes.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />
                  <Col md={6}>
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text="Fees charged by a homeowners association (HOA) to cover the costs of maintaining common areas, amenities, and shared facilities within a community or development.
 "
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        HOA Cost
                      </h6>
                      <p>
                        {data?.cost_hoa.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text="Insurance coverage purchased by property owners to protect against potential risks and losses, such as property damage, liability claims, or loss of rental income.
 "
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Homeowner Insurance
                      </h6>
                      <p>
                        {data?.cost_homeowner_insurance.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </p>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />
                  <Col md={6}>
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text=" The professional management of an investment property by a third-party property management company, which typically includes tasks such as tenant screening, rent collection, maintenance, and property inspections.
"
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Property Management
                      </h6>
                      <p>
                        {data?.cost_property_management.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="flex-column-reverse flex-md-row">
                  <Col md={6} className="border-end-2">
                    <div className="mt-2 mb-2 text-muted">
                      <h6 className="mb-1 ">
                        <Info
                          classNames="fs-sm"
                          placement="top"
                          text=" The basic services required for a property, such as electricity, water, gas, sewage, and garbage disposal, which are necessary for maintaining the property and providing a comfortable living environment for tenants. "
                        >
                          <span
                            style={{ marginRight: "4px" }}
                            className="d-block text-left fw-medium fs-md text-carre-gray-bg"
                          >
                            <FaInfoCircle style={{ marginBottom: 3 }} />
                          </span>
                        </Info>
                        Utilities (cost)
                      </h6>
                      <p>
                        {data?.cost_utilities_cost.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  </Col>
                  <hr className="d-block d-md-none" />
                  {/*<Col md={6}>*/}
                  {/*  <div className="mt-2 mb-2 text-muted">*/}
                  {/*    <h6 className="mb-1 ">*/}
                  {/*      Annual DOA LLC administration and filing fees*/}
                  {/*    </h6>*/}
                  {/*    <p>*/}
                  {/*      {data?.cost_annual_doa_llc.toLocaleString("en-US", {*/}
                  {/*        style: "currency",*/}
                  {/*        currency: "USD",*/}
                  {/*      })}*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                </Row>
              </div>
            </Row>
          </div>
        );
      case "ORDERBOOK":
        return <OrderBook id={id} marketStage={data?.market_stage_name} />;

      case "RESERVETOKEN":
        return <ReserveTokenPage id={data?.id} viewMode={viewMode} />;
      case "SELLTOKEN":
        return <SellTokenPage id={data?.id} viewMode={viewMode} />;
      case "ORDER":
        return <OrderPage id={data?.id} viewMode={viewMode} />;
      case "TRANSFERS":
        return <TransfersPage id={data?.id} viewMode={viewMode} />;
      case "BALANCE":
        return <BalancePage id={data?.id} viewMode={viewMode} />; // case "BUYINGPROCCCES":
      //   return (
      //     <div className="m-4">
      //       <div>
      //         <BsCheckCircle className="text-carre-purple" size="40" />
      //         <span className="mx-2">
      //           Property listed as upcoming on December 2, 2022
      //         </span>
      //         <div
      //           style={{ marginLeft: "17px" }}
      //           className="p-4 border-start border-5 border-carre-purple"
      //         >
      //           Wdawdadawd
      //         </div>
      //       </div>
      //
      //       <div>
      //         <BsRecordCircle className="text-carre-purple" size="40" />
      //         <span className="mx-2">
      //           Property listed in the primary market on December 22, 2022
      //         </span>
      //         <div
      //           style={{ marginLeft: "17px" }}
      //           className="p-4 border-start border-5 border-carre-gray-bg "
      //         >
      //           Wdawdadawd
      //         </div>
      //       </div>
      //       <div>
      //         <BsCircle className="text-carre-gray-bg" size="40" />
      //         <span className="mx-2">
      //           End of primary market and start of secondary market on December
      //           27, 2022
      //         </span>
      //         <div style={{ marginLeft: "13px" }} className="p-4">
      //           Wdawdadawd
      //         </div>
      //       </div>
      //     </div>
      //   );
      case "DOCUMENTS":
        return (
          <div>
            {propertyDocument && propertyDocument.length === 0 ? (
              <Alert className="text-center fs-sm p-2 fw-semibold">
                No document available
              </Alert>
            ) : (
              <>
                {
                  <div
                    className={"mt-4"}
                    style={{
                      position: "relative",
                      overflow: "auto",
                      minHeight: "500px",
                      maxHeight: "500px",
                    }}
                  >
                    <div className="">
                      <div>
                        <div>
                          <div className="pb-5 overflow-hidden">
                            <table className="table table-bordered table-sm table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">File</th>
                                  <th scope="col">Created At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {propertyDocument &&
                                  propertyDocument?.map &&
                                  propertyDocument?.map((item) => (
                                    <tr key={item?.id}>
                                      <td>
                                        <td>
                                          <div
                                            className={"d-flex h-100"}
                                            onClick={() =>
                                              redirectToPdf(item.document_url)
                                            }
                                          >
                                            <AiOutlineFilePdf
                                              size={30}
                                              className={"pe-2"}
                                            />
                                            {item.document_display_name}
                                          </div>
                                        </td>
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: "350px",
                                          maxHeight: "220px",
                                        }}
                                      >
                                        {new Date(
                                          item?.created_at
                                        ).toLocaleDateString("en-US")}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        );
      case "MNGTUPDATE":
        return (
          <div
            className="my-5"
            style={{
              height:
                !data?.property_comment || data?.property_comment.length === 0
                  ? "100px"
                  : "auto",
            }}
          >
            {data?.property_comment?.length === 0 ? (
              <Alert className="text-center fs-sm p-2 fw-semibold mt-1">
                No update for this property
              </Alert>
            ) : (
              data?.property_comment?.map((item) => (
                <div className="my-4">
                  <h4 dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p
                    className="overflow-hidden text-muted collapse show"
                    style={{ wordWrap: "break-word" }}
                    id="collapseSummary"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></p>
                </div>
              ))
            )}
          </div>
        );
    }
  }

  const { selectedTab, setSelectedTab, setSignUpModalOpen } = useTabContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    refetch();
    propertyDocumentRefetch();
    if (data?.market_stage_name === "Secondary") {
      setSelectedTab("ORDERBOOK");
    }
  }, [id, data, refetch]);
  const roleUrl = GetRoleNavigationPath();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    // setRender(tabName);
  };

  // Ref to the scrollable container
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      const containerWidth = navRef.current.offsetWidth;
      const selectedTabElement = navRef.current.querySelector(".active");
      if (selectedTabElement) {
        const tabWidth = selectedTabElement.offsetWidth;
        const tabLeftOffset = selectedTabElement.offsetLeft;
        let tabCenterOffset = tabLeftOffset + tabWidth / 2 - containerWidth / 2;
        tabCenterOffset = Math.max(tabCenterOffset, 0);
        setScrollPosition(tabCenterOffset);
      }
    }
  }, [selectedTab]);

  const navbarContainerStyle = {
    overflowX: "auto",
    scrollBehavior: "smooth",
  };

  // In your component, calculate the total width of tabs and container width
  const tabsWidth = useMemo(() => {
    if (navRef.current) {
      return Array.from(navRef.current.children).reduce(
        (totalWidth, child) => totalWidth + child.offsetWidth,
        0
      );
    }
    return 0;
  }, [navRef.current]);

  const containerWidth = navRef.current ? navRef.current.offsetWidth : 0;

  const navScrollableStyle = {
    display: "flex",
    flexWrap: "nowrap",
    ...(tabsWidth > containerWidth
      ? {
          // transform: `translateX(-${scrollPosition}px)`, // Center the selected tab
        }
      : {}),
  };

  const navItemStyle = {
    padding: "0 10px",
  };
  const amenityIcons = {
    swimming_pool: {
      icon: <FaSwimmingPool size={25} />,
      text: "Swimming Pool",
    },
    gym: {
      icon: <CgGym size={25} />,
      text: "Gym",
    },
    air_condition: {
      icon: <GrFan size={25} />,
      text: "AC",
    },
    security: {
      icon: <MdOutlineSecurity size={25} />,
      text: "Security",
    },
    laundry_facilities: {
      icon: <MdLocalLaundryService size={25} />,
      text: "Laundry",
    },
    games_room: {
      icon: <CgGames size={25} />,
      text: "Games Room",
    },
    storage: {
      icon: <FiArchive size={25} />,
      text: "Storage",
    },
    barbecue_area: {
      icon: <MdOutdoorGrill size={25} />,
      text: "Barbecue Area",
    },
    tech_workspace: {
      icon: <MdOutlineDesktopMac size={25} />,
      text: "Tech Workspace",
    },
    free_wifi: {
      icon: <AiOutlineWifi size={25} />,
      text: "Free Wifi",
    },
    sand_volleyball: {
      icon: <MdSportsVolleyball size={25} />,
      text: "Sand Volleyball",
    },
  };

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const maxAmenitiesToShow = 5;

  // Filter amenities based on whether they are available
  const filteredAmenities =
    data && data.amenities
      ? Object.entries(data.amenities).filter(
          ([amenityName, isAvailable]) => isAvailable
        )
      : [];
  // Display only a subset of amenities based on showAllAmenities state
  const displayedAmenities = showAllAmenities
    ? filteredAmenities
    : filteredAmenities.slice(0, maxAmenitiesToShow);

  const toggleShowAllAmenities = () => {
    setShowAllAmenities(!showAllAmenities);
  };
  const [viewMode, setViewMode] = useState("card"); // New state for view mode
  const isViewModeVisible = [
    "RESERVETOKEN",
    "SELLTOKEN",
    "ORDER",
    "TRANSFERS",
    "BALANCE",
  ].includes(selectedTab);

  const { cookies } = useAuth();
  const [cookies1, setCookies, removeCookie] = useCookies();

  return (
    <>
      <div className="d-flex flex-wrap container">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`col-12 col-md-${
              data?.market_stage_name === "Upcoming" ? "6" : "4"
            } col-lg-${
              data?.market_stage_name === "Upcoming" ? "6" : "4"
            } pb-2 pt-2`}
          >
            <Card
              className={`shadow ${
                card?.bg === "bg-carre-blue" ? "bg-carre-blue text-white" : ""
              } h-100 ${card?.class}`}
            >
              <Card.Body className="d-flex flex-column">
                <Card.Title
                  className={`${card?.style} d-flex justify-content-between fs-lg fw-medium`}
                >
                  <span>
                    {card?.titleInfo !== undefined && (
                      <Info
                        classNames="fs-sm "
                        placement="top"
                        text={card?.titleInfo}
                      >
                        <span
                          style={{ marginRight: "4px" }}
                          className={`d-block text-left fw-medium fs-md  ${
                            card?.bg === "bg-carre-blue"
                              ? "text-white"
                              : "text-carre-gray-bg"
                          }`}
                        >
                          <FaInfoCircle style={{ marginBottom: 3 }} />
                        </span>
                      </Info>
                    )}
                    {card?.title}{" "}
                  </span>
                  <span>{card?.cardTitleValue} </span> {/* New value */}
                </Card.Title>{" "}
                <div className="mt-auto">
                  {card?.bigValue ? (
                    <div className="d-flex justify-content-center h2">
                      <span>{card?.bigValue}</span>
                    </div>
                  ) : (
                    card?.values?.map((value, idx) => (
                      <div
                        key={idx}
                        className={`d-flex justify-content-between fs-sm ${
                          card?.bg === "bg-carre-blue"
                            ? " text-white"
                            : "text-black"
                        } ${
                          value === "Not a daily cash flow"
                            ? "text-muted border-top"
                            : ""
                        }`}
                      >
                        <span className="text-truncate">
                          {card?.infoValues !== undefined && (
                            <Info
                              classNames="fs-sm"
                              placement="top"
                              text={card?.infoValues[idx]}
                            >
                              <span
                                style={{
                                  marginRight: "4px",
                                }}
                                className={`d-block text-left fw-medium fs-md   ${
                                  card?.bg === "bg-carre-blue"
                                    ? "text-white"
                                    : "text-carre-gray-bg"
                                }`}
                              >
                                <FaInfoCircle style={{ marginBottom: 3 }} />
                              </span>
                            </Info>
                          )}
                          {card?.text[idx]}
                        </span>
                        <span
                          className={` ${
                            card?.bg === "bg-carre-blue"
                              ? "text-white"
                              : "text-carre-blue"
                          }${
                            value === "Not a daily cash flow"
                              ? "text-muted border-top"
                              : ""
                          }`}
                        >
                          {value}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      {/*proeprty title*/}
      <div className="container mb-2">
        <Row>
          <Col sm={10}>
            <div className="mt-2 h-100 ">
              <p className="text-carre-black fs-md fw-bold">
                {data?.property_name}
              </p>
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}
                className="fs-md"
              >
                {data?.bedroom_count > 0 && (
                  <span>
                    {checkPlural(data?.bedroom_count, "Bedroom", "Bedrooms")}
                  </span>
                )}
                {data?.bathrooms > 0 && (
                  <span>
                    {checkPlural(data?.bathrooms, "Bathroom", "Bathrooms")}
                  </span>
                )}

                {data?.parking > 0 && (
                  <span>
                    {checkPlural(data?.parking, "Parking", "Parkings")}
                  </span>
                )}
                {data?.property_area > 0 && (
                  <span>{data?.property_area} m²</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="d-block d-lg-none mb-3 mx-3">
        {data?.property_owned_token !== null && (
          <div
            className="position-absolute text-center"
            style={{ left: "0px", top: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fs-md text-carre-blue  bg-carre-gray p-2 rounded-pill mt-1"
              style={{ fontSize: "14px" }}
            >
              <span className="mt-2">
                {data?.property_owned_token?.token_qty}
              </span>{" "}
              <CarreToken />
            </p>
          </div>
        )}
        <Splide
          className=""
          options={{
            perPage: 1,
            height: "25rem",
            rewind: true,
            gap: "1rem",
          }}
          aria-labelledby="basic-example-heading"
        >
          {data?.image?.map((slide) => (
            <SplideSlide key={slide.image_url}>
              <img
                src={slide.image_url}
                alt="Default"
                style={{
                  objectFit: "cover",
                }}
                className="w-100 h-100 rounded-2"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="d-none d-lg-block container">
        <LightBox data={data} />
      </div>
      <div className="container d-flex gap-4 align-items-center ">
        <span className="text-muted">
          <span>{data?.address.street_name}</span>
          {data?.description.street_name} {data?.address.city_name}
          ,&nbsp;
          {data?.address.country_state} {data?.address.country_name}
          &nbsp;
          {data?.address.zipcode}
        </span>
      </div>
      <div className="container mb-4 ">
        <Row>
          <Col>
            {/*<Button*/}
            {/*  variant="white outline-carre-red"*/}
            {/*  className="d-block d-md-none "*/}
            {/*  size="md"*/}
            {/*  onClick={() => window.open(data?.contract_url, "_blank")}*/}
            {/*>*/}
            {/*  <img src={avalanche} width={15} />*/}

            {/*  <div className="tooltip-text d-none d-md-block fs-xs text-center">*/}
            {/*    &nbsp; Block chain address*/}
            {/*  </div>*/}
            {/*</Button>*/}
            {allFalse === false && (
              <div className="flex flex-wrap gap-5">
                <h5 className="my-2">Amenities:</h5>
                <Row
                  as="ul"
                  sm={2}
                  xs={1}
                  md={2}
                  lg={2}
                  className="list-unstyled gy-1 gx-5 mb-1 text-nowrap"
                >
                  {displayedAmenities.map(([amenityName]) => (
                    <Col as="li" className=" " key={amenityName}>
                      <div className="d-inline-block text-black mx-2">
                        {amenityIcons[amenityName]?.icon}&nbsp;&nbsp;
                      </div>
                      <div className="fs-md align-middle d-inline-block">
                        {amenityIcons[amenityName]?.text}
                      </div>
                    </Col>
                  ))}
                </Row>
                {filteredAmenities.length >= 6 && (
                  <div className="ms-auto mx-5">
                    <a
                      onClick={toggleShowAllAmenities}
                      aria-controls="moreAbout"
                      aria-expanded={showAllAmenities}
                      className={`collapse-label${
                        showAllAmenities ? "" : " collapsed"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {showAllAmenities ? "Show less" : "Show more"}
                    </a>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Col className="p-3 overflow-visible h-100 d-none d-md-block">
            <div className="d-flex flex-column align-items-end justify-content-end h-100">
              <Row>
                <Col className="w-100">
                  <Button
                    onClick={() =>
                      cookies.token
                        ? navigate(`/${roleUrl}/buy/${data?.id}`)
                        : setSignUpModalOpen(true)
                    }
                    variant={
                      data?.market_stage_name === "Upcoming"
                        ? "carre-blue text-white"
                        : "carre-blue text-white"
                    }
                    size="md"
                  >
                    {data?.market_stage_name === "Upcoming" ? "Reserve" : "Buy"}
                  </Button>
                </Col>
                {data?.market_stage_name !== "Upcoming" && (
                  <Col>
                    <Button
                      disabled={data?.property_owned_token === null}
                      onClick={() => {
                        navigate(`/${roleUrl}/sell/${data?.id}`);
                      }}
                      variant="carre-red text-white"
                      className="mb-3"
                      size="md"
                    >
                      Sell
                    </Button>
                  </Col>
                )}
              </Row>

              <div className="text-center h6">
                <Info
                  classNames="fs-xs text-left"
                  placement="top"
                  list={[
                    "Rental Income: Earn daily income from rental payments generated by the properties you invest in.\n",
                    "Appreciation: Benefit from the potential increase in property value over time, allowing your investment to grow.\n",
                    "Vacation Points: Enjoy the flexibility to use vacation points for complimentary stays at our properties, adding an extra perk to your investment portfolio. Vacation Points can also be sold on the platform",
                  ]}
                  text="
The Expected (Annual) Total Return is the sum of three annual return that Carre offers to his investors. :"
                >
                  <div className="text-muted">
                    <span className=" float-start">Expected Total Return</span>
                    <span className="mx-1 text-carre-blue float-end">
                      {(
                        data?.net_expected_cash_return +
                        data?.vacation_token_exceptions +
                        data?.net_appreciation
                      ).toFixed(2)}{" "}
                      %
                    </span>
                  </div>
                </Info>
              </div>
            </div>
          </Col>
        </Row>

        <hr className="my-4 w-full" />

        {isViewModeVisible && (
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="outline-primary"
              className="mx-2"
              onClick={() => setViewMode("table")}
              disabled={viewMode === "table"}
            >
              Table View
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setViewMode("card")}
              disabled={viewMode === "card"}
            >
              Card View
            </Button>
          </div>
        )}
        <div style={navbarContainerStyle}>
          <Nav ref={navRef} style={navScrollableStyle} className="mb-4">
            <NavItem>
              <Nav.Link
                active={selectedTab === "DETAILS"}
                onClick={() => handleTabClick("DETAILS")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "DETAILS" ? "#3899da" : "#b0b0b0",
                }}
              >
                Details
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "FINANCIAL"}
                onClick={() => handleTabClick("FINANCIAL")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "FINANCIAL" ? "#3899da" : "#b0b0b0",
                }}
              >
                Financial
              </Nav.Link>
            </NavItem>
            {/*<NavItem>*/}
            {/*  {" "}*/}
            {/*  <Nav.Link*/}
            {/*    active={selectedTab === "HISTORICALRETURN"}*/}
            {/*    onClick={() => handleTabClick("HISTORICALRETURN")}*/}
            {/*    style={{*/}
            {/*      ...navItemStyle,*/}
            {/*      color:*/}
            {/*        selectedTab === "HISTORICALRETURN" ? "#3899da" : "#b0b0b0",*/}
            {/*      whiteSpace: "nowrap",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Historical Return{" "}*/}
            {/*  </Nav.Link>*/}
            {/*</NavItem>*/}
            <NavItem>
              <Nav.Link
                active={selectedTab === "ORDERBOOK"}
                onClick={() => handleTabClick("ORDERBOOK")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "ORDERBOOK" ? "#3899da" : "#b0b0b0",
                  whiteSpace: "nowrap",
                }}
              >
                Order Book
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                active={selectedTab === "MNGTUPDATE"}
                onClick={() => handleTabClick("MNGTUPDATE")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "MNGTUPDATE" ? "#3899da" : "#b0b0b0",
                }}
              >
                Update
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                active={selectedTab === "DOCUMENTS"}
                onClick={() => handleTabClick("DOCUMENTS")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "DOCUMENTS" ? "#3899da" : "#b0b0b0",
                }}
              >
                Documents
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "TRANSFERS"}
                onClick={() => handleTabClick("TRANSFERS")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "TRANSFERS" ? "#3899da" : "#b0b0b0",
                }}
              >
                Transfers
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "BALANCE"}
                onClick={() => handleTabClick("BALANCE")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "BALANCE" ? "#3899da" : "#b0b0b0",
                }}
              >
                Balance
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                active={selectedTab === "RESERVETOKEN"}
                onClick={() => handleTabClick("RESERVETOKEN")}
                className=""
                style={{
                  ...navItemStyle,
                  color: selectedTab === "RESERVETOKEN" ? "#3899da" : "#b0b0b0",

                  whiteSpace: "nowrap",
                }}
              >
                Reserve Details
              </Nav.Link>
            </NavItem>

            {/*<NavItem>*/}
            {/*  <Nav.Link*/}
            {/*    active={selectedTab === "ORDER"}*/}
            {/*    onClick={() => handleTabClick("ORDER")}*/}
            {/*    style={{*/}
            {/*      ...navItemStyle,*/}
            {/*      color: selectedTab === "ORDER" ? "#3899da" : "#b0b0b0",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Order*/}
            {/*  </Nav.Link>*/}
            {/*</NavItem>*/}

            <NavItem>
              <Nav.Link
                active={selectedTab === "SELLTOKEN"}
                onClick={() => handleTabClick("SELLTOKEN")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "SELLTOKEN" ? "#3899da" : "#b0b0b0",
                  whiteSpace: "nowrap",
                }}
              >
                Orders
              </Nav.Link>
            </NavItem>
          </Nav>
        </div>

        <div>
          {data?.market_stage_name !== "Submitted" && (
            <Navbar
              fixed="bottom"
              bg="white"
              variant="white"
              className="d-block d-md-none"
            >
              <Container className="d-flex justify-content-center align-items-center">
                <Nav className="d-block d-md-none align-items-center ">
                  <Button
                    style={{ width: "130px" }}
                    size="sm"
                    variant="carre-blue btn-lg mx-2"
                    onClick={() => {
                      cookies.token
                        ? navigate(`/${roleUrl}/buy/${data?.id}`)
                        : setSignUpModalOpen(true);
                      if (process.env.REACT_APP_IS_PRODUCTION !== "false") {
                        setCookies("event_id", uuidv4());
                        // eslint-disable-next-line no-undef
                        fbq(
                          "track",
                          "AddToCart",
                          {},
                          {
                            event_id: cookies["event_id"],
                            external_id: JSON.parse(
                              localStorage.getItem("utmParams")
                            )?.external_id,
                          }
                        );
                      }
                    }}
                  >
                    {data?.market_stage_name === "Upcoming" ? "Reserve" : "Buy"}
                  </Button>
                  {data?.market_stage_name !== "Upcoming" && (
                    <Button
                      disabled={data?.property_owned_token === null}
                      style={{ width: "130px" }}
                      size="sm"
                      variant="carre-red btn-lg mx-2"
                      onClick={() => {
                        navigate(`/${roleUrl}/sell/${data?.id}`);
                      }}
                    >
                      Sell
                    </Button>
                  )}
                </Nav>
              </Container>
            </Navbar>
          )}
        </div>
        <hr />

        {show(selectedTab)}
      </div>
      <Footer />
    </>
  );
}

export default PeviewProperty;
