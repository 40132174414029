import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { useGetOrderDetailsById } from "../hooks/useGetOrderDetails";
import { useGetOrderBookById } from "../hooks/useGetOrderBookById";
import { FaArrowDown, FaArrowUp, FaInfoCircle } from "react-icons/fa";
import Info from "./Tooltip";
import { useGetTransfersForOrderBook } from "../hooks/useGetTransfersForOrderBook";
import moment from "moment";

const OrderBook = ({ id, marketStage, onlyOrder }) => {
  const { data: orderDetails, refetch: refetchOrderDetails } =
    useGetOrderDetailsById(id);
  const { data: orders, refetch: refetchOrders } = useGetOrderBookById(id);
  const { data: transfers, refetch: refetchTransfers } =
    useGetTransfersForOrderBook(id);

  useEffect(() => {
    refetchOrderDetails();
    refetchOrders();
    refetchTransfers();
  }, [id, marketStage, onlyOrder]);

  const buyOrders = orders?.filter((order) => order.type === "BUY").slice(0, 5);

  const sellOrders = orders
    ?.filter((order) => order.type === "SELL")
    .slice(0, 5);

  const renderOrderRows = (orderList, orderType) => {
    return orderList?.map((order, i) => {
      const maxTokenQty = Math.max(
        ...orderList.map((order) => order.token_qty)
      );
      const percentFilled = (100 / maxTokenQty) * order?.token_qty;

      const orderRowStyle = {
        padding: "6px",
        background: `linear-gradient(270deg, ${
          orderType === "SELL" ? "rgba(255,255,255,0)" : "rgba(255,255,255,0)"
        } ${percentFilled}%, #fff ${percentFilled}%)`,
        color: "#000",
        fontSize: "14px",
      };
      return (
        <div key={i} style={orderRowStyle} className="text-dark fw-medium">
          <Row>
            <Col xs={6}>
              <span className="d-block text-left mx-2">
                {order?.token_price}
              </span>
            </Col>

            <Col xs={6}>
              <span className="d-block text-end">{order?.available_token}</span>
            </Col>
            {/*<Col xs={4} className="text-end">*/}
            {/*  <span>*/}
            {/*    {order?.difference > 0 ? (*/}
            {/*      <FaArrowUp size={15} className="mb-1" />*/}
            {/*    ) : (*/}
            {/*      <FaArrowDown size={15} className="mb-1" />*/}
            {/*    )}*/}
            {/*    {order?.difference.toFixed(1)}*/}
            {/*  </span>*/}
            {/*</Col>*/}
          </Row>
        </div>
      );
    });
  };

  return (
    <div className="my-2">
      {!onlyOrder && (
        <Row className="justify-content-center">
          <Col sm={12} md={6} className="mb-3 mb-md-0">
            <Card>
              <Card.Body>
                <Card.Title className="fs-md fs-sm-sm fw-medium">
                  Last Price
                </Card.Title>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="fw-light fs-sm" id="token-tooltip">
                      The last tradable value in this context would be the most
                      recent price at which these property shares were bought or
                      sold.
                    </Tooltip>
                  }
                >
                  <Card.Text
                    style={{ fontSize: "35px", color: "rgba(44,138,197,0.76)" }}
                    className="price-tooltip"
                  >
                    {orderDetails?.token_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Card.Text>
                </OverlayTrigger>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={6} className="mb-3 mb-md-0">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="fw-light fs-sm" id="market-cap-tooltip">
                  Property Market Cap is calculated by multiplying the total
                  number of shares or shares representing ownership in the real
                  estate properties by the current market price per share or
                  share.{" "}
                </Tooltip>
              }
            >
              <Card>
                <Card.Body>
                  <Card.Title className="fw-medium">Market Cap</Card.Title>

                  <Card.Text
                    style={{ fontSize: "40px", color: "rgba(44,138,197,0.76)" }}
                    className="price-tooltip"
                  >
                    {orderDetails?.market_cap.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Card.Text>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          </Col>
        </Row>
      )}
      {marketStage === "Secondary" && (
        <Row>
          {orders?.length === 0 ? (
            <Alert className="text-center fs-sm p-2 fw-semibold mt-2">
              No data in order book.
            </Alert>
          ) : (
            <>
              {!onlyOrder && <p className="fw-medium fs-4 ">Order Book</p>}
              <Col sm={12} md={12}>
                <Row className="mt-3">
                  <Col xs={6}>
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text="It indicates the monetary amount required to purchase the share for a particular real estate or property asset."
                    >
                      <span className="d-block text-left fw-medium text-dark fs-md ">
                        Price ($) <FaInfoCircle />
                      </span>
                    </Info>
                  </Col>
                  <Col xs={6} className="text-end">
                    <Info
                      classNames="fs-sm"
                      placement="top"
                      text="The amount remaining from what the user wanted to buy is indicated by the colors of the filled percentage field."
                    >
                      <span className="d-block text-dark fs-md fw-medium text-end">
                        Size <FaInfoCircle />
                      </span>
                    </Info>
                  </Col>
                  {/*<Col xs={4} className="text-end">*/}
                  {/*  <Info*/}
                  {/*    classNames="fs-sm"*/}
                  {/*    placement="top"*/}
                  {/*    text="It indicates the difference in monetary amount required to purchase the share for a particular real estate or property asset."*/}
                  {/*  >*/}
                  {/*    <span className="d-block text-left fw-medium text-dark fs-lg">*/}
                  {/*      Diff (%) <FaInfoCircle />*/}
                  {/*    </span>*/}
                  {/*  </Info>*/}
                  {/*</Col>*/}
                </Row>
                <div
                  className="order-container"
                  style={{ background: "rgba(255,174,174,0.39)" }}
                >
                  {renderOrderRows(sellOrders?.reverse(), "SELL")}
                </div>
                <div
                  className="fs-6 fs-sm-sm fw-medium bg-carre-gray  d-flex justify-content-between"
                  style={{
                    padding: "5px",
                    color: "#000",
                    fontSize: "14px",
                  }}
                >
                  <span className="text-carre-blue px-2 fw-medium">
                    {orderDetails?.token_price}
                  </span>
                  <span>Last Price</span>
                </div>

                <div
                  className="order-container"
                  style={{ background: "rgba(195,227,248,0.52)" }}
                >
                  {renderOrderRows(buyOrders?.reverse(), "BUY")}
                </div>
              </Col>
            </>
          )}
        </Row>
      )}

      {!onlyOrder && (
        <>
          <p className="fw-medium fs-4 my-4">Latest Transaction</p>

          <Row className="mt-3">
            <Col xs={6} className="text-black fw-medium fs-sm">
              <span>Date / Time (UTC)</span>
            </Col>
            <Col xs={3} className="fw-medium text-black fs-sm">
              <span>Quantity</span>
            </Col>
            <Col xs={3} className="text-end text-black fw-medium fs-sm">
              <span>Price per share ($)</span>
            </Col>
          </Row>

          {transfers?.map((transfer, index) => (
            <Row className={index % 2 === 0 ? "bg-carre-gray" : ""}>
              <Col xs={6}>
                <span className="d-block text-left">
                  {moment(transfer.created_at).format("MMM D HH:mm")}
                </span>
              </Col>
              <Col xs={3}>
                <span className="text-center">{transfer?.token_qty}</span>
              </Col>

              <Col xs={3} className="text-end">
                <span className="d-block text-right">
                  {transfer?.token_price}
                </span>
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );
};

export default OrderBook;
