import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackButton from "../../components/GoBackButton";
import { useBuyPropertyToken } from "../../hooks/useBuyPropertyToken";
import { useGetAccount } from "../../hooks/useGetAccount";
import { useGetAppConfig } from "../../hooks/useGetAppConfig";
import { useGetMarketStageById } from "../../hooks/useGetMArketStageById";
import { useGetPropertyForBuySellRequest } from "../../hooks/useGetPropertyForBuySellRequest";
import { GetRoleNavigationPath } from "../../routeUtils";
import ConfirmModal from "./ConfirmModal";
import { useTabContext } from "./TabContext";
import Tooltip from "../../components/Tooltip";
import { MdInfo } from "react-icons/md";
import { useGetOrderDetailsById } from "../../hooks/useGetOrderDetails";
import moment from "moment";
import { useGetOrderBookById } from "../../hooks/useGetOrderBookById";
import TransferModal from "./OrderDataInfo";
import useCheckUserSteps from "../../hooks/checkKycStep";
import useCheckUserStepIsBuy from "../../hooks/useCheckUserStepIsBuy";
import OrderBook from "../../components/OrderBook";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

function BuyPropertyTokenScreen() {
  const params = useParams();
  const id = params.id;
  const { data: accountData, refetch: accoundRefetch } = useGetAccount();
  const orderType = "BUY";
  const { data, refetch } = useGetPropertyForBuySellRequest(id, orderType);
  const { data: orders, refetch: refetchOrders } = useGetOrderBookById(id);
  const { setShowOnboarding } = useTabContext();
  const hasCompletedSteps = useCheckUserSteps();
  const isBuyStep = useCheckUserStepIsBuy();
  const [cookies, setCookies, removeCookie] = useCookies();

  const highestBid = orders
    ?.filter((order) => order.type === "BUY")
    .reduce(
      (highest, order) => {
        return order.token_price > highest.token_price ? order : highest;
      },
      { token_price: -Infinity }
    ) || { token_price: "No data" };

  const lowestAsk = orders
    ?.filter((order) => order.type === "SELL")
    .reduce(
      (lowest, order) => {
        return order.token_price < lowest.token_price ? order : lowest;
      },
      { token_price: Infinity }
    ) || { token_price: "No data" };

  const { data: orderDetails } = useGetOrderDetailsById(id);

  const sellOrders = orders?.filter((order) => order.type === "SELL");

  // Find the highest token price among SELL orders
  const lowestSellOrder = sellOrders?.find(
    (order) =>
      order.token_price === Math.min(...sellOrders.map((o) => o.token_price))
  );

  const { data: marketStage, refetch: marketStageRefetch } =
    useGetMarketStageById(data?.property?.market_stage);
  const { data: fees } = useGetAppConfig();
  const [tokenRange, setTokenRange] = useState(1); // Initialize tokenRange to 0
  const [firstButton, setFirstButton] = useState(false);
  const [secondButton, setSecondButton] = useState(false);
  const [thirdButton, setThirdButton] = useState(false);
  const [fourthButton, setFourthButton] = useState(false);
  const [endPeriod, setEndPeriod] = useState("");

  const [tokenPrice, setTokenPrice] = useState(
    data?.property?.market_stage_name === "Upcoming"
      ? data?.property?.token_price_up_coming?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      : data?.property?.token_price?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      from_account_id: accountData?.id,
      number_of_token: 10,
    },
  });

  useEffect(() => {
    reset({ from_account_id: accountData?.id });
  }, [accountData, reset]);
  const notifySuccess = () => {
    data?.property?.market_stage_name === "Upcoming"
      ? toast.success("Share reserved successfully")
      : data?.property?.market_stage_name === "Secondary"
      ? toast.success("Share order created successfully")
      : toast.success("Share bought successfully");
  };

  const queryClient = useQueryClient();
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferData, setTransferData] = useState({});
  const { mutate, isLoading } = useMutation(useBuyPropertyToken, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["resever_tokens"]);
      queryClient.invalidateQueries({ queryKey: ["user_data"] });
      closeConfirmModal();
      setShowTransferModal(true);
      if (data?.transfer !== undefined) {
        setCookies("event_id", uuidv4());

        // eslint-disable-next-line no-undef
        fbq(
          "track",
          "Purchase",
          {
            value: data?.transfer.amount,
            currency: "USD",
          },
          {
            event_id: cookies["event_id"],
            external_id: JSON.parse(localStorage.getItem("utmParams"))
              ?.external_id,
          }
        );
      }
      setTransferData(data);
      notifySuccess();
      refetch().then();
      accoundRefetch().then();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });

  useEffect(() => {
    if (Number(tokenRange) === 1) {
      setFirstButton(true);
      setSecondButton(false);
      setThirdButton(false);
      setFourthButton(false);
    } else if (Number(tokenRange) === 5) {
      setFirstButton(false);
      setSecondButton(true);
      setThirdButton(false);
      setFourthButton(false);
    } else if (Number(tokenRange) === 10) {
      setFirstButton(false);
      setSecondButton(false);
      setThirdButton(true);
      setFourthButton(false);
    } else if (Number(tokenRange) === 25) {
      setFirstButton(false);
      setSecondButton(false);
      setThirdButton(false);
      setFourthButton(true);
    } else {
      setFirstButton(false);
      setSecondButton(false);
      setThirdButton(false);
      setFourthButton(false);
    }
  }, [tokenRange]);

  const calculatedRange = Math.round(
    (data?.property?.number_of_token / 100) *
      fees?.token_allowed_percent_to_buy -
      data?.property.owned_token
  );
  const calculatedRangeForReserve = Math.round(
    (data?.property?.allowed_token_to_reserve / 100) *
      fees?.token_allowed_percent_to_buy -
      data?.property.reserved_token
  );

  useEffect(() => {
    refetch().then();
  }, [id]);

  useEffect(() => {
    if (data?.property?.market_stage_name === "Upcoming") {
      setTokenPrice(data?.property?.token_price_up_coming);
    } else if (data?.property?.market_stage_name === "Secondary") {
      setTokenPrice(lowestSellOrder?.token_price || orderDetails?.token_price);
    } else {
      setTokenPrice(data?.property?.token_price);
    }
  }, [data, orderDetails]);

  const notifyError = (error) => toast.error(error);
  useEffect(() => {
    setValue("number_of_token", parseInt(tokenRange));
  }, [tokenRange]);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  // Handle opening and closing the ConfirmModal
  const openConfirmModal = () => {
    if (process.env.REACT_APP_IS_PRODUCTION !== "false") {
      setCookies("event_id", uuidv4());
      // eslint-disable-next-line no-undef
      fbq(
        "track",
        "InitiateCheckout",
        {},
        {
          event_id: cookies["event_id"],
          external_id: JSON.parse(localStorage.getItem("utmParams"))
            ?.external_id,
        }
      );
    }
    if (hasCompletedSteps) {
      setShowOnboarding(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  useEffect(() => {
    if (isBuyStep) {
      setShowOnboarding(false);
    }
  }, [isBuyStep]);
  const handleCloseModal = () => {
    setShowTransferModal(false);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  // Handler for confirming the token purchase
  const handleConfirmPurchase = () => {
    handleSubmit(onSubmit)();
  };
  useEffect(() => {
    setValue("number_of_token", 10);
  }, [id]);
  useEffect(() => {
    if (id) {
      setValue("id", id);
    }
  }, [tokenRange]);

  const onSubmit = (data) => {
    const mutationData = {
      id: id,
      account_id: data.from_account_id,
      token_qty: tokenRange,
      token_price: Number(tokenPrice),
      type: "BUY",
      end_period: data.end_period === "until_completed" ? "" : data.end_period,
    };

    mutate(mutationData);
  };

  const secondaryData = (
    <div>
      <div className="fs-lg fw-semibold">
        Buying {tokenRange} shares of {data?.property?.property_name} property
        at{" "}
        {tokenPrice?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        per share
        {data?.property?.market_stage_name === "Secondary" && " or lower"}
      </div>

      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Account:</div>
        <div className="flex-grow-1 text-end">
          Carré <span>($ {accountData?.balance.toFixed(2)})</span>
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Fees:</div>
        <div className="flex-grow-1 text-end">
          {calculatePrice(tokenRange * tokenPrice)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">
          {data?.property?.market_stage_name === "Secondary" && "Estimated "}
          Cost:
        </div>
        <div className="flex-grow-1 text-end">
          {(
            tokenRange * tokenPrice +
            calculatePrice(tokenRange * tokenPrice)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>

      {data?.property?.market_stage_name === "Secondary" && (
        <>
          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Ask:</div>
            <div className="flex-grow-1 text-end">
              {lowestAsk.token_price !== Infinity
                ? `${
                    lowestAsk.token_qty
                  } X ${lowestAsk.token_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : "No data"}
            </div>
          </div>
          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Bid:</div>
            <div className="flex-grow-1 text-end">
              {highestBid.token_price !== -Infinity
                ? `${
                    highestBid.token_qty
                  } X ${highestBid.token_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : "No data"}
            </div>
          </div>

          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Last:</div>
            <div className="flex-grow-1 text-end">
              {data?.property?.token_price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </div>
        </>
      )}

      <div className="mx-1 mt-4">As of {moment().format("MMMM Do YYYY")}</div>
    </div>
  );

  const totalTokens = data?.property.number_of_token;
  const ownedTokens = data?.property.owned_token;
  const ownershipPercentage = (ownedTokens / totalTokens) * 100;
  const roleUrl = GetRoleNavigationPath();
  const targetOwnershipPercentage = fees?.token_allowed_percent_to_buy;

  useEffect(() => {
    marketStageRefetch().then();
  }, [data]);

  function calculatePrice(amount) {
    let price = 0;
    if (marketStage?.name === "Primary") {
      price = (fees?.primary_buy_fee / 100) * amount;
    } else if (marketStage?.name === "Secondary") {
      price = (fees?.secondary_buy_fee / 100) * amount;
    }

    return price;
  }

  const { setDepositModalOpen } = useTabContext();
  return (
    <div className="">
      <GoBackButton className="mx-3" />
      <Row className="justify-content-center mb-5">
        <Row>
          <Col md={7}>
            <div style={{ height: "100%", overflow: "hidden" }}>
              <img
                src={data?.property?.property_image?.image_url}
                alt="Property"
                // className="w-100 rounded-2 h-100"
                // style={{
                //   objectFit: "fill",
                //   // maxHeight: "250px",
                // }}
              />
            </div>
          </Col>
          <Col md={5}>
            <div className="h-100 mt-3 mt-sm-0">
              <div>
                <div className="fs-4 fw-semibold">
                  {data?.property?.property_name}
                </div>

                <div>
                  <span className="text-muted">
                    {data?.property?.address.street_name}
                  </span>
                  <span className="text-muted">
                    {data?.property?.address.city_name}
                    ,&nbsp;
                    {data?.property?.address.country_state}{" "}
                    {data?.property?.address.country_name}
                    &nbsp;
                    {data?.property?.address.zipcode}
                  </span>
                  {data?.property?.owned_token > 0 && (
                    <Row className="mt-4">
                      <Row>
                        <Col>
                          <div className="d-inline-block">
                            <h6 className="text-muted">
                              Current Number Of Shares
                            </h6>
                            <h6 className="text-carre-blue">
                              {data?.property?.owned_token}
                            </h6>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-inline-block">
                            <h6 className="text-muted">
                              Current Position Value
                            </h6>
                            <h6 className="text-carre-blue">
                              {(
                                data?.property?.owned_token * 100
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {data?.property?.market_stage_name === "Secondary" && (
          <OrderBook id={id} marketStage={"Secondary"} onlyOrder={true} />
        )}
        <Row className="justify-content-center mt-3">
          <Row className="justify-content-evenly">
            <label className="text-muted h6">Property share quantity</label>
            {/*<div style={buttonContainerStyle}>*/}
            <div className="col-lg-2 col-md-6 mb-2">
              <Button
                size="sm"
                className="w-100 fw-normal overflow-hidden"
                onClick={() => setTokenRange(1)}
                variant="outline-carre-blue"
                active={firstButton}
              >
                <div style={{ textAlign: "center" }}>
                  <div>1 shares</div>
                  <div>
                    {data?.property?.market_stage_name === "Upcoming"
                      ? (
                          data?.property?.token_price_up_coming * 1
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : data?.property?.market_stage_name === "Secondary"
                      ? (tokenPrice * 1).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : (data?.property?.token_price * 1).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                  </div>
                </div>
              </Button>
            </div>
            <div className="col-lg-2 col-md-6 mb-2">
              <Button
                size="sm"
                className="w-100 fw-normal overflow-hidden"
                onClick={() => setTokenRange(5)}
                variant="outline-carre-blue"
                active={secondButton}
              >
                <div style={{ textAlign: "center" }}>
                  <div>5 shares</div>

                  {data?.property?.market_stage_name === "Upcoming"
                    ? (
                        data?.property?.token_price_up_coming * 5
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : data?.property?.market_stage_name === "Secondary"
                    ? (tokenPrice * 5).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : (data?.property?.token_price * 5).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                </div>
              </Button>
            </div>
            <div className="col-lg-2 col-md-6 mb-2">
              <Button
                size="sm"
                className="w-100 fw-normal overflow-hidden"
                onClick={() => setTokenRange(10)}
                variant="outline-carre-blue"
                active={thirdButton}
              >
                <div style={{ textAlign: "center" }}>
                  <div>10 shares</div>

                  {data?.property?.market_stage_name === "Upcoming"
                    ? (
                        data?.property?.token_price_up_coming * 10
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : data?.property?.market_stage_name === "Secondary"
                    ? (tokenPrice * 10).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : (data?.property?.token_price * 10).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                </div>
              </Button>
            </div>
            <div className="col-lg-2 col-md-6 mb-2">
              <Button
                size="sm"
                className="w-100 fw-normal overflow-hidden"
                onClick={() => setTokenRange(25)}
                variant="outline-carre-blue"
                active={fourthButton}
              >
                <div style={{ textAlign: "center" }}>
                  <div>25 shares</div>
                  {data?.property?.market_stage_name === "Upcoming"
                    ? (
                        data?.property?.token_price_up_coming * 25
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : data?.property?.market_stage_name === "Secondary"
                    ? (tokenPrice * 25).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : (data?.property?.token_price * 25).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                </div>
              </Button>
            </div>

            <div className="col-lg-4 col-md-6 mb-2">
              <Form.Group className="">
                <Form.Control
                  className="me-auto"
                  type="number"
                  id="tokenRangeInput"
                  name="tokenRangeInput"
                  min={0}
                  max={
                    data?.property?.market_stage_name === "Upcoming"
                      ? calculatedRangeForReserve
                      : calculatedRange
                  }
                  value={tokenRange}
                  onKeyPress={(event) => {
                    if (event.key < "0" || event.key > "9") {
                      event.preventDefault(); // Prevent non-numeric characters
                    }
                  }}
                  onChange={(event) => {
                    const input = event.target.value;
                    const maxValue =
                      data?.property?.market_stage_name === "Upcoming"
                        ? calculatedRangeForReserve
                        : calculatedRange;

                    if (input === "") {
                      setTokenRange("");
                    } else if (input > maxValue) {
                      toast.info(
                        "Quantity above limit, setting custom amount to maximum quantity"
                      );
                      setTokenRange(parseInt(maxValue));
                    } else {
                      setTokenRange(parseInt(input));
                    }
                  }}
                />
                <Form.Text className="text-muted">Custom amount</Form.Text>
              </Form.Group>
            </div>
          </Row>
          <Row>
            <Form.Group>
              <label className="text-muted h6">Payment method</label>
              <Row className="justify-content-between ">
                <Col md={8}>
                  <Form.Select
                    className={
                      errors.from_account_id
                        ? "text-carre-blue form-control border-carre-red mb-2"
                        : " form-control false"
                    }
                    {...register("from_account_id", {
                      required: true,
                    })}
                    disabled
                  >
                    <option key={1} value={accountData?.id}>
                      Carré Account{" "}
                      {accountData?.balance?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </option>
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <Button
                    className="w-100 mt-3 mt-md-0 fw-semibold"
                    onClick={() => {
                      setDepositModalOpen(true);
                    }}
                    variant="outline-carre-blue"
                  >
                    Deposit
                  </Button>
                </Col>
              </Row>
              {errors.from_account_id && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group>
              <Form.Label className="text-muted h6">
                Price per share{" "}
                <Tooltip
                  children={
                    <>
                      <MdInfo />
                    </>
                  }
                  text={
                    "When property is in secondary market you can chose the price"
                  }
                />
              </Form.Label>
              <Form.Control
                className="me-auto"
                placeholder="Amount"
                type="number"
                onChange={(event) => setTokenPrice(event.target.value)}
                value={tokenPrice}
                readOnly={data?.property?.market_stage_name !== "Secondary"}
                disabled={data?.property?.market_stage_name !== "Secondary"}
              />
            </Form.Group>
          </Row>
          {data?.property?.market_stage_name === "Secondary" && (
            <Row>
              <Form.Group>
                <Form.Label className="text-muted h6">
                  Offer valid until
                  <Tooltip
                    children={
                      <>
                        <MdInfo />
                      </>
                    }
                    text={"Until when do you wna tyour offert to be valide"}
                  />
                </Form.Label>
                <Form.Select
                  className={
                    errors.end_period
                      ? "text-carre-blue form-control border-carre-red mb-2"
                      : " form-control false"
                  }
                  {...register("end_period", {
                    required: true,
                  })}
                >
                  <option key={1} value={"until_completed"}>
                    Until Completed
                  </option>
                  <option key={2} value={"END_OF_DAY"}>
                    End of day
                  </option>
                </Form.Select>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Form.Group>
              <Form.Label className="text-muted h6">Fees</Form.Label>
              <Form.Control
                className="me-auto"
                placeholder="Amount"
                value={calculatePrice(tokenRange * tokenPrice)?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}
                disabled={true}
                readOnly={true}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-between">
            <label className="h6 text-muted"> Total Amount</label>
            <Col md={8}>
              <Form.Group>
                <Form.Label className="text-muted h6" visuallyHidden={true}>
                  Total Amount
                </Form.Label>
                <Form.Control
                  className="me-auto"
                  placeholder="Amount"
                  value={(
                    tokenRange * tokenPrice +
                    calculatePrice(tokenRange * tokenPrice)
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  readOnly={true}
                  disabled={true}
                />
              </Form.Group>
            </Col>

            <Col md={4} className=" small mb-5 ">
              <div>
                <Button
                  type={"submit"}
                  size="md"
                  disabled={isLoading}
                  variant="carre-blue text-white w-100 fw-semibold mt-3 mt-md-0"
                  onClick={openConfirmModal}
                >
                  {!isLoading
                    ? data?.property?.market_stage_name === "Upcoming"
                      ? "Reserve"
                      : "Place order"
                    : "Please wait"}
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </Row>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleConfirmPurchase}
        disableButton={isLoading}
        confirmButtonText={isLoading ? "Please Wait" : "Confirm"}
        title={`${
          data?.property?.market_stage_name === "Upcoming"
            ? "Reserve"
            : data?.property?.market_stage_name === "Secondary"
            ? "Order"
            : "Purchase"
        } Review`}
      >
        <div className="mt-2">{secondaryData}</div>
      </ConfirmModal>
      <TransferModal
        property={data?.property?.property_name}
        transferData={transferData}
        show={showTransferModal}
        isSecondary={data?.property?.market_stage_name === "Secondary"}
        handleClose={handleCloseModal}
      />
    </div>
  );
}

export default BuyPropertyTokenScreen;
